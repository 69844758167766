import AvaAzuki from '../../images/binders/avatars/azuki.png'
import AvaBeatrice from '../../images/binders/avatars/beatrice.png'
import AvaBonnie from '../../images/binders/avatars/bonnie.png'
import AvaCaesar from '../../images/binders/avatars/caesar.png'
import AvaCharles from '../../images/binders/avatars/charles.png'
import AvaDaria from '../../images/binders/avatars/daria.png'
import AvaDoki from '../../images/binders/avatars/doki.png'
import AvaDorothy from '../../images/binders/avatars/dorothy.png'
import AvaEdith from '../../images/binders/avatars/edith.png'
import AvaLeto from '../../images/binders/avatars/leto.png'
import AvaMona from '../../images/binders/avatars/mona.png'
import AvaRachel from '../../images/binders/avatars/rachel.png'
import AvaSuzie from '../../images/binders/avatars/suzie.png'
import AvaUnity from '../../images/binders/avatars/unity.png'
import AvaYuffie from '../../images/binders/avatars/yuffie.png'
import CardAzuki from '../../images/binders/cards/azuki.png'
import CardBeatrice from '../../images/binders/cards/beatrice.png'
import CardBonnie from '../../images/binders/cards/bonnie.png'
import CardCaesar from '../../images/binders/cards/caesar.png'
import CardDaria from '../../images/binders/cards/daria.png'
import CardDoki from '../../images/binders/cards/doki.png'
import CardDorothy from '../../images/binders/cards/dorothy.png'
import CardEdith from '../../images/binders/cards/edith.png'
import CardLeto from '../../images/binders/cards/leto.png'
import CardCharles from '../../images/binders/cards/lincoln.png'
import CardMona from '../../images/binders/cards/mona.png'
import CardRachel from '../../images/binders/cards/rachel.png'
import CardSuzie from '../../images/binders/cards/suzie.png'
import CardUnity from '../../images/binders/cards/unity.png'
import CardYuffie from '../../images/binders/cards/yuffie.png'
import Azuki from '../../images/binders/nobg/Azuki.png'
import Beatrice from '../../images/binders/nobg/Beatrice.png'
import Bonnie from '../../images/binders/nobg/Bonnie.png'
import Caesar from '../../images/binders/nobg/Caesar.png'
import Daria from '../../images/binders/nobg/Daria.png'
import Doki from '../../images/binders/nobg/Doki.png'
import Dorothy from '../../images/binders/nobg/Dorothy.png'
import Edith from '../../images/binders/nobg/Edith.png'
import Leto from '../../images/binders/nobg/Leto.png'
import Charles from '../../images/binders/nobg/Lincoln.png'
import Mona from '../../images/binders/nobg/Mona.png'
import Rachel from '../../images/binders/nobg/Rachel.png'
import Suzie from '../../images/binders/nobg/Suzie.png'
import Unity from '../../images/binders/nobg/Unity.png'
import Yuffie from '../../images/binders/nobg/Yuffie.png'

export enum BinderKeys {
  UNITY = 'unity',
  LETO = 'leto',
  BEATRICE = 'beatrice',
  MONA = 'mona',
  AZUKI = 'azuki',
  DOKI = 'doki',
  CHARLES = 'charles',
  EDITH = 'edith',
  BONNIE = 'bonnie',
  DOROTHY = 'dorothy',
  RACHEL = 'rachel',
  SUZIE = 'suzie',
  CAESAR = 'caesar',
  DARIA = 'daria',
  YUFFIE = 'yuffie',
}

export interface BinderConfig {
  [name: string]: {
    avatar: string
    card: string
    noBg: string
    name: string
    artist: string
    artistRef: string
  }
}

export const binderRevealOrder = [
  // day 1
  BinderKeys.UNITY,
  BinderKeys.LETO,
  BinderKeys.BEATRICE,
  // day 2
  BinderKeys.MONA,
  // day 3
  BinderKeys.AZUKI,
  // day 4
  BinderKeys.DOKI,
  // day 5
  BinderKeys.CHARLES,
  // day 6
  BinderKeys.EDITH,
  // day 7
  BinderKeys.BONNIE,
  // day 8
  BinderKeys.DOROTHY,
  // day 9
  BinderKeys.RACHEL,
  // day 10
  BinderKeys.SUZIE,
  // day 11
  BinderKeys.CAESAR,
  // day 12
  BinderKeys.DARIA,
  // day 13
  BinderKeys.YUFFIE,
]

export const binderConfig: BinderConfig = {
  [BinderKeys.UNITY]: {
    avatar: AvaUnity,
    card: CardUnity,
    noBg: Unity,
    name: 'Unity Lane',
    artist: 'OrangeSekaii',
    artistRef: 'https://twitter.com/Orangesekaii',
  },
  [BinderKeys.LETO]: {
    avatar: AvaLeto,
    card: CardLeto,
    noBg: Leto,
    name: 'Leto Crowley',
    artist: 'Tenyo',
    artistRef: 'https://www.pixiv.net/users/1241621',
  },

  [BinderKeys.BEATRICE]: {
    avatar: AvaBeatrice,
    card: CardBeatrice,
    noBg: Beatrice,
    name: 'Beatrice Laurent',
    artist: 'DHJS',
    artistRef: 'https://twitter.com/dhjs_0010',
  },

  [BinderKeys.MONA]: {
    avatar: AvaMona,
    card: CardMona,
    noBg: Mona,
    name: 'Mona Reiss',
    artist: 'Tenyo',
    artistRef: 'https://www.pixiv.net/users/1241621',
  },

  [BinderKeys.AZUKI]: {
    avatar: AvaAzuki,
    card: CardAzuki,
    noBg: Azuki,
    name: 'Azuki Ota',
    artist: 'Tenyo',
    artistRef: 'https://www.pixiv.net/users/1241621',
  },

  [BinderKeys.DOKI]: {
    avatar: AvaDoki,
    card: CardDoki,
    noBg: Doki,
    name: 'Doki Haru',
    artist: 'Tenyo',
    artistRef: 'https://www.pixiv.net/users/1241621',
  },

  [BinderKeys.CHARLES]: {
    avatar: AvaCharles,
    card: CardCharles,
    noBg: Charles,
    name: 'Charles William Lincoln',
    artist: 'Tenyo',
    artistRef: 'https://www.pixiv.net/users/1241621',
  },
  [BinderKeys.EDITH]: {
    avatar: AvaEdith,
    card: CardEdith,
    noBg: Edith,
    name: 'Edith Lange',
    artist: 'DHJS',
    artistRef: 'https://twitter.com/dhjs_0010',
  },
  [BinderKeys.BONNIE]: {
    avatar: AvaBonnie,
    card: CardBonnie,
    noBg: Bonnie,
    name: 'Bonnie Baxter',
    artist: 'DHJS',
    artistRef: 'https://twitter.com/dhjs_0010',
  },
  [BinderKeys.DOROTHY]: {
    avatar: AvaDorothy,
    card: CardDorothy,
    noBg: Dorothy,
    name: 'Dorothy Wright',
    artist: 'DHJS',
    artistRef: 'https://twitter.com/dhjs_0010',
  },
  [BinderKeys.RACHEL]: {
    avatar: AvaRachel,
    card: CardRachel,
    noBg: Rachel,
    name: 'Rachel Porter',
    artist: 'DHJS',
    artistRef: 'https://twitter.com/dhjs_0010',
  },
  [BinderKeys.SUZIE]: {
    avatar: AvaSuzie,
    card: CardSuzie,
    noBg: Suzie,
    name: 'Suzie Shi',
    artist: 'OrangeSekaii',
    artistRef: 'https://twitter.com/Orangesekaii',
  },
  [BinderKeys.CAESAR]: {
    avatar: AvaCaesar,
    card: CardCaesar,
    noBg: Caesar,
    name: 'Caesar Lazarus',
    artist: 'OrangeSekaii',
    artistRef: 'https://twitter.com/Orangesekaii',
  },
  [BinderKeys.DARIA]: {
    avatar: AvaDaria,
    card: CardDaria,
    noBg: Daria,
    name: 'Daria Dagger',
    artist: 'OrangeSekaii',
    artistRef: 'https://twitter.com/Orangesekaii',
  },
  [BinderKeys.YUFFIE]: {
    avatar: AvaYuffie,
    card: CardYuffie,
    noBg: Yuffie,
    name: 'Yuffie Fischer',
    artist: 'OrangeSekaii',
    artistRef: 'https://twitter.com/Orangesekaii',
  },
}
