import { Link } from 'gatsby'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ChildSectionShortWidth, H1, H2, ParentSection } from '../../components'
import {
  binderConfig,
  binderRevealOrder,
} from '../../features/chainbinders/config'
import Background1 from '../../images/binder-bg.svg'
interface StyleProps {
  image: any
}

export const Chainbinders: React.FC = () => {
  const { t } = useTranslation()
  const translatedBinders = binderRevealOrder.map((key) => {
    const binder = binderConfig[key]
    return { ...binder, translatedName: t(key), route: key, isMissing: false }
  })
  return (
    <StyledParentSection>
      <ChildSectionShortWidth>
        <Centering>
          <H1>{t('worthy')}</H1>
          <H2>{t('waitLegendary')}</H2>

          <StyledP>
            {t('pulsar')}
            <br />
            <br />
            {t('rareBinders')}
          </StyledP>
        </Centering>

        <ChainbinderContainer>
          {translatedBinders.map((binder, index) => {
            return (
              <Background key={index} to={`/chainbinders/${binder.route}`}>
                <ChainbinderBox>
                  <ChainbinderImg image={binder.avatar}> </ChainbinderImg>
                </ChainbinderBox>
                <ChainbinderName className="chainbinder-name">
                  {binder.translatedName}
                </ChainbinderName>
              </Background>
            )
          })}
        </ChainbinderContainer>
      </ChildSectionShortWidth>
    </StyledParentSection>
  )
}

export default Chainbinders

const StyledParentSection = styled(ParentSection)`
  background: url(${Background1}) no-repeat;
  background-size: cover;
`

const MissingBackground = styled.div`
  background: rgba(0, 0, 0, 0.3);
  border-radius: 18px;
  border: 2px solid rgba(255, 255, 255, 0.18);
  padding: 1rem;
  transition: 0.3s;

  @media (min-width: 750px) {
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
  }

  &:hover {
    border: 2px solid #00ffc2;
    transform: translate(0, -8px);
    transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1),
      -webkit-transform 0.5s cubic-bezier(0.19, 1, 0.22, 1);
  }
`

const Background = styled(Link)`
  background: rgba(0, 0, 0, 0.3);
  border-radius: 18px;
  border: 2px solid rgba(255, 255, 255, 0.18);
  padding: 1rem;
  transition: 0.3s;

  @media (min-width: 750px) {
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
  }

  &:hover {
    border: 2px solid #00ffc2;
    transform: translate(0, -8px);
    transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1),
      -webkit-transform 0.5s cubic-bezier(0.19, 1, 0.22, 1);
  }
`

const Centering = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 3rem;
  padding: 2rem;

  background: rgba(0, 0, 0, 0.3);
  border-radius: 18px;
  border: 1px solid rgba(255, 255, 255, 0.18);

  @media (min-width: 750px) {
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
  }
`

const StyledP = styled.p`
  color: #ffffff;
  columns: 500px;
  gap: 2rem;
  margin-top: 1rem;
  margin-bottom: 1rem;

  @media (min-width: 200px) {
    text-align: center;
  }

  @media (min-width: 750px) {
    text-align: left;
  }
`

const ChainbinderContainer = styled.section`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  column-gap: 2rem;
  row-gap: 4rem;
  justify-items: center;
  align-items: center;
  margin-top: 5rem;
  width: 100%;
`

const ChainbinderBox = styled.div`
  width: min-content;
  position: relative;
  height: min-content;
  border: 1px solid rgba(255, 255, 255, 0.18);

  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  border-radius: 18px;
  border: 1px solid rgba(255, 255, 255, 0.18);

  @media (min-width: 750px) {
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
  }
`

const ChainbinderName = styled.div`
  color: white;
  text-align: center;
  padding-top: 1rem;
  text-transform: uppercase;
  font-weight: 600;
  transition: 0.5s ease;

  ${ChainbinderBox}:hover & {
    background: #00ffc2;
    color: black;
    border: 2px solid #00ffc2;
  }
`

const ChainbinderImg = styled.div<StyleProps>`
  background: url(${(props) => props.image}) no-repeat;
  width: 219px;
  height: 273px;
  background-size: cover;
  background-position: top center;
  border-bottom: none;
  transition: 0.5s ease;
  border-radius: 18px;
`
